module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\Najmi\\Desktop\\SIMPLR\\simplr.company-web-gatsby\\src\\containers\\layout\\layout.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simplr Logic Sdn Bhd","short_name":"Simplr","theme_color":"#086ad8","background_color":"#ffffff","display":"standalone","scope":"/","start_url":"/","icon":"src/assets/images/favicon.png","icons":[{"src":"assets/images/favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"assets/images/favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"assets/images/favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"assets/images/favicon/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"assets/images/favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"assets/images/favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"assets/images/favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"assets/images/favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"assets/images/favicon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d58c27c432f91e5a60804e852d789c9b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page","/404","/404.html"],"useClassNames":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about-us/","/case-studies/","/contact-us/","/index-appointment/","/index-infotechno/","/index-processing/","/index-resolutions/","/index-services/","/it-services/","/it-solutions/","/leadership/","/it-service/*","/case-study/*","/case-study/*","/blogs/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
