// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-banking-institutions-js": () => import("./../../../src/pages/banking-institutions.js" /* webpackChunkName: "component---src-pages-banking-institutions-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-elements-accordion-js": () => import("./../../../src/pages/elements/accordion.js" /* webpackChunkName: "component---src-pages-elements-accordion-js" */),
  "component---src-pages-elements-box-icon-js": () => import("./../../../src/pages/elements/box-icon.js" /* webpackChunkName: "component---src-pages-elements-box-icon-js" */),
  "component---src-pages-elements-box-image-js": () => import("./../../../src/pages/elements/box-image.js" /* webpackChunkName: "component---src-pages-elements-box-image-js" */),
  "component---src-pages-elements-box-large-image-js": () => import("./../../../src/pages/elements/box-large-image.js" /* webpackChunkName: "component---src-pages-elements-box-large-image-js" */),
  "component---src-pages-elements-button-js": () => import("./../../../src/pages/elements/button.js" /* webpackChunkName: "component---src-pages-elements-button-js" */),
  "component---src-pages-elements-call-to-action-js": () => import("./../../../src/pages/elements/call-to-action.js" /* webpackChunkName: "component---src-pages-elements-call-to-action-js" */),
  "component---src-pages-elements-client-logo-js": () => import("./../../../src/pages/elements/client-logo.js" /* webpackChunkName: "component---src-pages-elements-client-logo-js" */),
  "component---src-pages-elements-counters-js": () => import("./../../../src/pages/elements/counters.js" /* webpackChunkName: "component---src-pages-elements-counters-js" */),
  "component---src-pages-elements-dividers-js": () => import("./../../../src/pages/elements/dividers.js" /* webpackChunkName: "component---src-pages-elements-dividers-js" */),
  "component---src-pages-elements-flexible-image-slider-js": () => import("./../../../src/pages/elements/flexible-image-slider.js" /* webpackChunkName: "component---src-pages-elements-flexible-image-slider-js" */),
  "component---src-pages-elements-google-map-js": () => import("./../../../src/pages/elements/google-map.js" /* webpackChunkName: "component---src-pages-elements-google-map-js" */),
  "component---src-pages-elements-gradation-js": () => import("./../../../src/pages/elements/gradation.js" /* webpackChunkName: "component---src-pages-elements-gradation-js" */),
  "component---src-pages-elements-lists-js": () => import("./../../../src/pages/elements/lists.js" /* webpackChunkName: "component---src-pages-elements-lists-js" */),
  "component---src-pages-elements-message-box-js": () => import("./../../../src/pages/elements/message-box.js" /* webpackChunkName: "component---src-pages-elements-message-box-js" */),
  "component---src-pages-elements-popup-video-js": () => import("./../../../src/pages/elements/popup-video.js" /* webpackChunkName: "component---src-pages-elements-popup-video-js" */),
  "component---src-pages-elements-pricing-box-js": () => import("./../../../src/pages/elements/pricing-box.js" /* webpackChunkName: "component---src-pages-elements-pricing-box-js" */),
  "component---src-pages-elements-progress-bar-js": () => import("./../../../src/pages/elements/progress-bar.js" /* webpackChunkName: "component---src-pages-elements-progress-bar-js" */),
  "component---src-pages-elements-progress-circle-js": () => import("./../../../src/pages/elements/progress-circle.js" /* webpackChunkName: "component---src-pages-elements-progress-circle-js" */),
  "component---src-pages-elements-social-networks-js": () => import("./../../../src/pages/elements/social-networks.js" /* webpackChunkName: "component---src-pages-elements-social-networks-js" */),
  "component---src-pages-elements-tabs-js": () => import("./../../../src/pages/elements/tabs.js" /* webpackChunkName: "component---src-pages-elements-tabs-js" */),
  "component---src-pages-elements-team-member-js": () => import("./../../../src/pages/elements/team-member.js" /* webpackChunkName: "component---src-pages-elements-team-member-js" */),
  "component---src-pages-elements-testimonials-js": () => import("./../../../src/pages/elements/testimonials.js" /* webpackChunkName: "component---src-pages-elements-testimonials-js" */),
  "component---src-pages-elements-timeline-js": () => import("./../../../src/pages/elements/timeline.js" /* webpackChunkName: "component---src-pages-elements-timeline-js" */),
  "component---src-pages-elements-typed-text-js": () => import("./../../../src/pages/elements/typed-text.js" /* webpackChunkName: "component---src-pages-elements-typed-text-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-services-js": () => import("./../../../src/pages/it-services.js" /* webpackChunkName: "component---src-pages-it-services-js" */),
  "component---src-pages-it-solutions-js": () => import("./../../../src/pages/it-solutions.js" /* webpackChunkName: "component---src-pages-it-solutions-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-portfolio-bak-js": () => import("./../../../src/pages/portfolio-bak.js" /* webpackChunkName: "component---src-pages-portfolio-bak-js" */),
  "component---src-pages-portfolio-portfolio-banking-institutions-js": () => import("./../../../src/pages/portfolio/portfolio-banking-institutions.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-banking-institutions-js" */),
  "component---src-pages-portfolio-portfolio-insurance-institutions-js": () => import("./../../../src/pages/portfolio/portfolio-insurance-institutions.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-insurance-institutions-js" */),
  "component---src-pages-portfolio-portfolio-workflow-automation-js": () => import("./../../../src/pages/portfolio/portfolio-workflow-automation.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-workflow-automation-js" */),
  "component---src-pages-pricing-plan-js": () => import("./../../../src/pages/pricing-plan.js" /* webpackChunkName: "component---src-pages-pricing-plan-js" */),
  "component---src-pages-services-services-cloud-solution-js": () => import("./../../../src/pages/services/services-cloud-solution.js" /* webpackChunkName: "component---src-pages-services-services-cloud-solution-js" */),
  "component---src-pages-services-services-it-outsourcing-js": () => import("./../../../src/pages/services/services-it-outsourcing.js" /* webpackChunkName: "component---src-pages-services-services-it-outsourcing-js" */),
  "component---src-pages-services-services-product-development-js": () => import("./../../../src/pages/services/services-product-development.js" /* webpackChunkName: "component---src-pages-services-services-product-development-js" */),
  "component---src-pages-services-services-project-rescue-js": () => import("./../../../src/pages/services/services-project-rescue.js" /* webpackChunkName: "component---src-pages-services-services-project-rescue-js" */),
  "component---src-pages-services-services-support-maintenance-js": () => import("./../../../src/pages/services/services-support-maintenance.js" /* webpackChunkName: "component---src-pages-services-services-support-maintenance-js" */),
  "component---src-pages-services-services-system-integration-js": () => import("./../../../src/pages/services/services-system-integration.js" /* webpackChunkName: "component---src-pages-services-services-system-integration-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-why-choose-us-js": () => import("./../../../src/pages/why-choose-us.js" /* webpackChunkName: "component---src-pages-why-choose-us-js" */),
  "component---src-templates-author-template-author-template-js": () => import("./../../../src/templates/author-template/author-template.js" /* webpackChunkName: "component---src-templates-author-template-author-template-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/blog-list/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-case-study-template-case-study-template-js": () => import("./../../../src/templates/case-study-template/case-study-template.js" /* webpackChunkName: "component---src-templates-case-study-template-case-study-template-js" */),
  "component---src-templates-category-template-category-template-js": () => import("./../../../src/templates/category-template/category-template.js" /* webpackChunkName: "component---src-templates-category-template-category-template-js" */),
  "component---src-templates-date-template-date-template-js": () => import("./../../../src/templates/date-template/date-template.js" /* webpackChunkName: "component---src-templates-date-template-date-template-js" */),
  "component---src-templates-it-solution-template-it-solution-template-js": () => import("./../../../src/templates/it-solution-template/it-solution-template.js" /* webpackChunkName: "component---src-templates-it-solution-template-it-solution-template-js" */),
  "component---src-templates-search-template-search-template-js": () => import("./../../../src/templates/search-template/search-template.js" /* webpackChunkName: "component---src-templates-search-template-search-template-js" */),
  "component---src-templates-service-template-service-template-js": () => import("./../../../src/templates/service-template/service-template.js" /* webpackChunkName: "component---src-templates-service-template-service-template-js" */),
  "component---src-templates-tag-template-tag-template-js": () => import("./../../../src/templates/tag-template/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-tag-template-js" */)
}

